module.exports = createAuthedFetch

const sign = require('cf-signature')
const qs = require('querystring')

function createAuthedFetch(apiUrl) {
  function makeAuthedRequest(method, path, data, options) {
    const key = window.localStorage.getItem('apiKey')
    const id = window.localStorage.getItem('apiId')
    let sessionTimeout = window.localStorage.getItem('apiTimeout')

    if (!options) {
      options = { headers: {} }
    }

    // Set a 24 hour session timeout. After which point you have to login again
    if (sessionTimeout !== null && sessionTimeout < Date.now())
      return logout('timeout')

    if (!isAuthed()) return logout()

    sessionTimeout = Date.now() + 24 * 60 * 1000 * 60
    window.localStorage.setItem('apiTimeout', sessionTimeout)

    let uri = apiUrl + path

    const isForm = data instanceof FormData

    const contentType =
      options.headers['Content-Type'] || getContentType(method, isForm)
    const date = new Date().toUTCString()
    const query =
      hasBodyData(method) || !data
        ? ''
        : '?' + qs.stringify(data).replace(/'/g, "'")
    const signature = sign(key, method, contentType, date, path + query)
    const req = {
      method: method,
      headers: Object.assign(
        {
          Authorization: 'Catfish ' + id + ':' + signature,
          'x-cf-date': date
        },
        options.headers,
        !isForm ? { 'Content-Type': contentType } : {}
      )
    }

    if (data instanceof FormData) {
      req.body = data
    } else if (hasBodyData(method)) {
      req.body = JSON.stringify(data)
    } else {
      uri += query
    }

    return fetch(uri, req)
  }

  function logout(reason) {
    window.localStorage.removeItem('apiKey')
    window.localStorage.removeItem('apiTimeout')
    window.localStorage.removeItem('aclRoles')
    document.location = '/login?reason=' + (reason || '')
  }

  function isAuthed() {
    return (
      !!window.localStorage.getItem('apiKey') &&
      !!window.localStorage.getItem('apiId')
    )
  }

  function hasBodyData(method) {
    return ['GET', 'DELETE'].indexOf(method) === -1
  }

  function getContentType(method, isForm) {
    if (isForm) {
      return 'multipart/form-data'
    }

    switch (method) {
      case 'GET':
        return ''
      case 'DELETE':
        return 'text/plain'
      default:
        return 'application/json'
    }
  }

  return makeAuthedRequest
}
